import React from 'react';
import './feature.css';

function Feature({ title, text }) {
  return (
    <div className="gpt3Features-containerFeature">
    <div className="gpt3Features-containerFeature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div className="gpt3Features-container_feature-text">
      <p>{text}</p>
    </div>
  </div>
  )
}

export default Feature