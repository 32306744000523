import React from 'react';
import {Blog,Features,Footer, Header, Possibility,WhatGPT3 } from './containers';
import {Article,Brand,Cta, Feature, Navbar } from './components';
import './App.css';

function App() {
  return (
    <div className='App'>
      <div className='gradientBg'>
        <Navbar/>
        <Header/>
      </div>
      <Brand/>
      <WhatGPT3/>
      <Features />
      <Possibility />
      <Cta/>
      <Blog/>
      <Footer/>
    </div>
  );
}

export default App;
